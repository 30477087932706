import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Recordings {
  [key: number]: RecordingsSummary | null;
}

export type ShowAudioPlayerFn = (
  lessonNumber: number | null | undefined,
  studentId: number,
  onClose?: () => void,
) => void;

export interface RecordingsSummary {
  total: number;
  new: number;
}

export interface SetRecordingsStateAction {
  recordings: Recordings;
}
export interface RecordingsState {
  recordings: Recordings;
  showAudioPlayer?: ShowAudioPlayerFn;
  skipSetReviewed?: boolean;
}

export const initialRecordingsState: RecordingsState = {
  recordings: {} as Recordings,
};

const setRecordingsReducer = (state: RecordingsState, { payload }: PayloadAction<SetRecordingsStateAction>) => {
  state.recordings = payload.recordings;
};

export const recordingsSlice = createSlice({
  name: 'recordings',
  initialState: initialRecordingsState,
  reducers: {
    setRecordings: setRecordingsReducer,
  },
});

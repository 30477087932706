import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SetFiltersAction, filterSlice } from './FiltersSlice';

// @TODO: at the moment we only get school name from SA dashboard and not yet available in reporting-api.
// once changed, remove optional in name and populate from reporting-api. Remove in user slice too.
export interface SchoolState {
  id: number;
  name?: string;
  timezone: string | null;
  startDate: string;
  startDateInTimezone: Date | null;
}

const setSchoolReducer = (state: SchoolState, { payload }: PayloadAction<SetFiltersAction>) => {
  if (payload.school) {
    state.id = payload.school.id;
    state.timezone = payload.school.timezone;
    state.startDate = payload.school.startDate;
    state.startDateInTimezone = payload.school.startDateInTimezone;
  }
};

export const initialSchoolState: SchoolState = {
  id: 0,
  name: '',
  startDate: '',
  timezone: '',
  startDateInTimezone: null,
};

export const schoolSlice = createSlice({
  name: 'school',
  initialState: initialSchoolState,
  reducers: {},
  extraReducers: {
    [filterSlice.actions.setFilters.type]: setSchoolReducer,
  },
});

import { ManageClassHeader } from 'app/ManageClassApp';
import { createSlice } from '@reduxjs/toolkit';

export const initialManageClassHeaderState: ManageClassHeader = {
  title: '',
}

const headerManageClassSlice = createSlice({
  name: 'manage-class-header',
  initialState: initialManageClassHeaderState,
  reducers: {},
});

export default headerManageClassSlice

import {
  combineReducers,
  configureStore,
  DeepPartial,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import headerSlice from './slices/end-of-year-report/HeaderSlice';
import headerManageClassSlice from './slices/manage-class/HeaderSlice';
import headerBenchmarkAssessmentSlice from './slices/benchmark-assessment-report/HeaderSlice';
import { filterSlice } from './slices/FiltersSlice';
import { userSlice } from './slices/UserSlice';
import { studentsSlice as dataCenterStudentsSlice } from './slices/data-center/StudentsSlice';
import { studentsSlice as endOfYearReportStudentsSlice } from './slices/end-of-year-report/StudentsSlice';
import { studentsSlice as benchmarkAssessmentReportStudentsSlice } from './slices/benchmark-assessment-report/StudentsSlice';
import { classOrGroupSlice } from './slices/benchmark-assessment-report/ClassOrGroupSlice';
import { recordingsSlice } from './slices/data-center/RecordingsSlice';
import { schoolSlice } from './slices/SchoolSlice';

export const {
  setStudents: setDataCenterStudents,
  setGoalLesson,
  setStudentSorting: setDataCenterStudentSorting,
  setSelectedStudentsMap,
  changeStudentSelectState,
  changeAllStudentsSelectState,
} = dataCenterStudentsSlice.actions;

export const {
  setStudents: setEndOfYearReportStudents,
  setEoyGoalLesson,
  setStudentSorting: setEndOfYearReportStudentSorting,
} = endOfYearReportStudentsSlice.actions;

export const {
  setStudents: setBenchmarkAssessmentReportStudents,
  setBenchmarkAssessmentGoalLesson,
  setStudentSorting: setBenchmarkAssessmentReportStudentSorting,
} = benchmarkAssessmentReportStudentsSlice.actions;

export const {
  setFilters,
  setPlacementYearFilters,
} = filterSlice.actions;

export const {
  setRecordings,
} = recordingsSlice.actions;

export const {
  setUserInfo,
} = userSlice.actions;

const dataCenterReducer = combineReducers({
  students: dataCenterStudentsSlice.reducer,
  recordings: recordingsSlice.reducer,
});

const endOfYearReportReducer = combineReducers({
  students: endOfYearReportStudentsSlice.reducer,
  header: headerSlice.reducer,
})

const manageClassReducer = combineReducers({
  header: headerManageClassSlice.reducer,
})

const benchmarkAssessmentReportReducer = combineReducers({
  students: benchmarkAssessmentReportStudentsSlice.reducer,
  header: headerBenchmarkAssessmentSlice.reducer,
  classOrGroup: classOrGroupSlice.reducer,
})

const reducer = combineReducers({
  dataCenter: dataCenterReducer,
  endOfYearReport: endOfYearReportReducer,
  manageClass: manageClassReducer,
  benchmarkAssessmentReport: benchmarkAssessmentReportReducer,
  filters: filterSlice.reducer,
  user: userSlice.reducer,
  school: schoolSlice.reducer,
});

export type RootState = ReturnType<typeof reducer>;

// Since we need to pass a function from SA Dashboard, we need to turn off serialize check.
// By default, state can not contain non-serializable values such as functions, promises etc.
// https://redux-toolkit.js.org/api/getDefaultMiddleware#development
const middleware = getDefaultMiddleware({
  serializableCheck: false,
});

const createStore = (
  preloadedState: DeepPartial<RootState>,
) => configureStore(
  {
    reducer,
    middleware,
    preloadedState,
  },
);

export default createStore;

import { IParentFilter, ISubFilter } from '@achieve-3000/a3k-react-ui';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterParentOptions } from '@constants/Filters';
import { Curriculum } from '@constants/Curriculum';
import { SchoolState } from './SchoolSlice';

export type UpdateSADashboardSelectedClassGroupFn = (selected: string) => void;
export type UpdateSADashboardSelectedPlacementTestFn = (selected: string) => void;

export interface SAFilter extends ISubFilter {
  curriculum?: Curriculum;
  isEnglishClass?: boolean;
  hasTeacherAssigned?: boolean;
}
export interface SAFilterParent extends IParentFilter {
  subFilters: Array<SAFilter>;
}

export interface FiltersState {
  classesAndGroups: {
    filters: SAFilterParent[] | null;
    selectedFilter: SAFilter | null;
    selectedParentId: FilterParentOptions | null;
    updateSADashboardSelectedClassGroup?: UpdateSADashboardSelectedClassGroupFn;
    updateSADashboardSelectedPlacementTest?: UpdateSADashboardSelectedPlacementTestFn;
    isLoaded?: boolean;
    placementYearFilter?: string;
  }
}

export interface SetFiltersAction {
  filters: SAFilterParent[] | null;
  selectedFilter: SAFilter | null;
  selectedParentId: FilterParentOptions | null;
  school?: SchoolState | null;
  isLoaded?: boolean;
}

export interface SetPlacementYearFiltersAction {
  placementYearFilter?: string
}

const setFiltersReducer = (state: FiltersState, { payload }: PayloadAction<SetFiltersAction>) => {
  state.classesAndGroups.filters = payload.filters;
  state.classesAndGroups.selectedFilter = payload.selectedFilter;
  state.classesAndGroups.selectedParentId = payload.selectedParentId;
  state.classesAndGroups.isLoaded = payload.isLoaded;
}

const setPlacementYearFiltersReducer = (state: FiltersState, { payload }: PayloadAction<SetPlacementYearFiltersAction>) => {
  state.classesAndGroups.placementYearFilter = payload.placementYearFilter;
}

export const initialFiltersState: FiltersState = {
  classesAndGroups: {
    filters: null,
    selectedFilter: null,
    selectedParentId: null,
    isLoaded: false,
    placementYearFilter: 'Beginning Of Year',
  },  
}

export const filterSlice = createSlice({
  name: 'filter',
  initialState: initialFiltersState,
  reducers: {
    setFilters: setFiltersReducer,
    setPlacementYearFilters: setPlacementYearFiltersReducer,
  },
});

import React from 'react';
import ReactDOM from 'react-dom';
import { CommonAppProps } from '@constants/Common';
import { ShowAudioPlayerFn } from '@redux/slices/data-center/RecordingsSlice';
import DataCenterProvider from '@providers/DataCenterProvider';
import getAppInfo from '@helpers/common/App';

const DataCenterChunk = () => import('../pages/data-center/Container');

export interface DataCenterAppProps extends CommonAppProps {
  showAudioPlayer: ShowAudioPlayerFn;
  skipSetReviewed: string;
}

export type DataCenterAppFn = (selector: string, props: DataCenterAppProps) => void;

const DataCenterApp = async (selector: string, props: DataCenterAppProps) => {
  const { default: DataCenterInstance } = await DataCenterChunk();
  const dataCenterContainer = document.getElementById(selector);
  if (!dataCenterContainer) {
    // eslint-disable-next-line no-console 
    console.error(
      'Unable to mount DataCenter application to selector: ',
      selector,
    );
    return;
  }
  const { userInfo, REPORTING_API_URL } = getAppInfo(props);

  ReactDOM.render(
    (
      <DataCenterProvider
        token={props.token}
        language="en"
        userInfo={userInfo}
        updateSADashboardSelectedClassGroup={props.updateSADashboardSelectedClassGroup}
        showAudioPlayer={props.showAudioPlayer}
        skipSetReviewed={Boolean(props.skipSetReviewed)}
        reportingApiURL={REPORTING_API_URL}
      >
        <DataCenterInstance />
      </DataCenterProvider>
    ),
    dataCenterContainer,
  );
};

export default DataCenterApp;

import { SortDirection } from '@constants/Common';
import { LessonLevel, StatusAndLessonLevel } from '@constants/Curriculum';
import { IRowData, StatusTypes } from '@achieve-3000/a3k-react-ui';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const END_OF_GRADE_GOAL = 'endOfGradeGoal';
export const ONE_ACADEMIC_YEAR_GOAL = 'academicYearGoal';
export type EndOfGradeGoalHeaderType = 'endOfGradeGoal' | 'academicYearGoal';

export interface Student extends IRowData {
  id: number;
  externalStudentId: string;
  studentName: string;
  completedCurriculum: boolean;
  gradeLevel: string;
  startOfYearPlacement: StatusAndLessonLevel;
  midYearPlacement: StatusAndLessonLevel;
  endOfYearPlacement: StatusAndLessonLevel;
  endOfGradeGoal: LessonLevel | null;
  academicYearGoal: LessonLevel | null;
  goalProgressStatus: { status: StatusTypes | null, label: string };
  academicYearGoalProgressStatus: { status: StatusTypes | null, label: string };

  startOfYearLevelNumber: number;
  startOfYearLevelName: string | null;
  startOfYearLessonNumber: number;
  startOfYearLessonName: string | null;

  midYearLevelNumber: number;
  midYearLevelName: string | null;
  midYearLessonNumber: number;
  midYearLessonName: string | null;

  endOfYearLevelNumber: number;
  endOfYearLevelName: string | null;
  endOfYearLessonNumber: number;
  endOfYearLessonName: string | null;

  academicYearGoalLevelNumber: number;
  academicYearGoalLevelName: string | null;
  academicYearGoalLessonNumber: number;
  academicYearGoalLessonName: string | null;

  endOfGradeGoalLevelNumber: number;
  endOfGradeGoalLevelName: string | null;
  endOfGradeGoalLessonNumber: number;
  endOfGradeGoalLessonName: string | null;

  sortCompletedCurriculum: string | null;

  sortStartOfYearLevelLesson: string | null;
  sortStartOfYearPlacementByStatus: string | null;
  sortStartOfYearNotYetPlaced: number;

  sortAcademicGoal: string | null;
  sortEndOfGradeGoal: string | null;
  sortAcademicNotYetPlaced: number;
  sortEndOfGradeNotYetPlaced: number;

  sortMidYearLevelLesson: string | null;
  sortMidYearPlacementByStatus: string | null;
  sortMidYearNotYetPlaced: number;

  sortEndOfYearLevelLesson: string | null;
  sortEndOfYearNotYetPlaced: number;

  sortGoalProgressStatus: string | null;
  sortAcademicYearGoalProgressStatus: string | null;
}

export interface StudentsState {
  students: Student[];
  selectedEoyGoalLesson: EndOfGradeGoalHeaderType;
  sortBy: string;
  sortDirection: SortDirection;
  dateTimeLoaded: Date;
}

export interface SetStudentsAction {
  students: Student[];
  dateTimeLoaded: Date;
}

const setStudentsReducer = (state: StudentsState, { payload }: PayloadAction<SetStudentsAction>) => {
  state.students = payload.students;
  state.dateTimeLoaded = payload.dateTimeLoaded;
}

export interface SetStudentSortingAction {
  sortBy: string;
  sortDirection: SortDirection;
}

const setStudentSortingReducer = (state: StudentsState, { payload }: PayloadAction<SetStudentSortingAction>) => {
  state.sortBy = payload.sortBy;
  state.sortDirection = payload.sortDirection;
}

export interface ChangeSelectEoyGoalLessonStateAction {
  selectedEoyGoalLesson: EndOfGradeGoalHeaderType;
}

const setEoyGoalLessonReducer = (state: StudentsState, { payload }: PayloadAction<ChangeSelectEoyGoalLessonStateAction>) => {
  state.selectedEoyGoalLesson = payload.selectedEoyGoalLesson;
}

const initialStudentsState: StudentsState = {
  students: [],
  selectedEoyGoalLesson: END_OF_GRADE_GOAL,
  sortBy: 'studentName',
  sortDirection: 'asc',
  dateTimeLoaded: new Date(),
}

export const studentsSlice = createSlice({
  name: 'end-of-year-report-students',
  initialState: initialStudentsState,
  reducers: {
    setStudents: setStudentsReducer,
    setEoyGoalLesson: setEoyGoalLessonReducer,
    setStudentSorting: setStudentSortingReducer,
  },
});

import { EndOfYearReportHeader } from 'app/EndOfYearReportApp';
import { createSlice } from '@reduxjs/toolkit';

export const initialHeaderState: EndOfYearReportHeader = {
  question: '',
  name: '',
  description: '',
}

const headerSlice = createSlice({
  name: 'end-of-year-report-header',
  initialState: initialHeaderState,
  reducers: {},
});

export default headerSlice

import { BenchmarkAssessmentReportHeader } from 'app/BenchmarkAssessmentReportApp';
import { createSlice } from '@reduxjs/toolkit';

export const initialHeaderState: BenchmarkAssessmentReportHeader = {
  question: '',
  name: '',
  description: '',
}

const headerSlice = createSlice({
  name: 'benchmark-assessment-report-header',
  initialState: initialHeaderState,
  reducers: {},
});

export default headerSlice

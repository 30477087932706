import React from 'react';
import TranslationProvider, { LanguageCodes } from '@providers/TranslationProvider';
import UIThemeProvider from '@providers/UIThemeProvider';
import StoreProvider from '@providers/StoreProvider';
import DataProvider from '@providers/DataProvider';
import { UserInfo } from '@redux/slices/UserSlice';
import { UpdateSADashboardSelectedClassGroupFn, initialFiltersState } from '@redux/slices/FiltersSlice';
import { ShowAudioPlayerFn, initialRecordingsState } from '@redux/slices/data-center/RecordingsSlice';
import { initialSchoolState } from '@redux/slices/SchoolSlice';

interface DataCenterProviderProps {
  children: React.ReactNode;
  language: LanguageCodes;
  userInfo: UserInfo;
  token: string;
  reportingApiURL: string;
  updateSADashboardSelectedClassGroup?: UpdateSADashboardSelectedClassGroupFn;
  showAudioPlayer?: ShowAudioPlayerFn;
  skipSetReviewed?: boolean;
}

const DataCenterProvider = ({
  children,
  language,
  token,
  userInfo,
  reportingApiURL,
  updateSADashboardSelectedClassGroup,
  showAudioPlayer,
  skipSetReviewed,
}: DataCenterProviderProps) => {
  const preloadedState = {
    user: {
      userInfo,
    },
    school: {
      ...initialSchoolState,
      name: userInfo.schoolName,
    },
    filters: {
      classesAndGroups: {
        ...initialFiltersState.classesAndGroups,
        updateSADashboardSelectedClassGroup,
      },
    },
    dataCenter: {
      recordings: {
        ...initialRecordingsState,
        showAudioPlayer,
        skipSetReviewed,
      },
    },
  }

  return (
    <DataProvider
      token={token}
      apiURL={reportingApiURL}
    >
      <StoreProvider preloadedState={preloadedState}>
        <UIThemeProvider>
          <TranslationProvider language={language}>
            {children}
          </TranslationProvider>
        </UIThemeProvider>
      </StoreProvider>
    </DataProvider>
  );
}

export default DataCenterProvider;

import React from 'react';
import ReactDOM from 'react-dom';
import { CommonAppProps } from '@constants/Common';
import BenchmarkAssessmentReportProvider from '@providers/BenchmarkAssessmentReportProvider';
import getAppInfo from '@helpers/common/App';
import { UpdateSADashboardSelectedPlacementTestFn } from '@redux/slices/FiltersSlice';

const BenchmarkAssessmentReportChunk = () => import('../pages/reports/benchmark-assessment/Container');
export interface BenchmarkAssessmentReportHeader {
  question: string;
  name: string;
  description: string;
}

export interface BenchmarkAssessmentReportAppProps extends CommonAppProps{
  reportHeader: BenchmarkAssessmentReportHeader;
  updateSADashboardSelectedPlacementTest: UpdateSADashboardSelectedPlacementTestFn;
}

export type BenchmarkAssessmentReportAppFn = (selector: string, props: BenchmarkAssessmentReportAppProps) => void;

const BenchmarkAssessmentReportApp = async (selector: string, props: BenchmarkAssessmentReportAppProps) => {
  const { default: BenchmarkAssessmentReportInstance } = await BenchmarkAssessmentReportChunk();
  const benchmarkAssessmentReportContainer = document.getElementById(selector);
  if (!benchmarkAssessmentReportContainer) {
    // eslint-disable-next-line no-console
    console.error(
      'App Unable to mount Benchmark assessment report application to selector: ',
      selector,
    );
    return;
  }
  const { userInfo, REPORTING_API_URL } = getAppInfo(props);

  ReactDOM.render(
    (
      <BenchmarkAssessmentReportProvider
        token={props.token}
        language="en"
        userInfo={userInfo}
        reportingApiURL={REPORTING_API_URL}
        updateSADashboardSelectedClassGroup={props.updateSADashboardSelectedClassGroup}
        reportHeader={props.reportHeader}
        updateSADashboardSelectedPlacementTest={props.updateSADashboardSelectedPlacementTest}
      >
        <BenchmarkAssessmentReportInstance />
      </BenchmarkAssessmentReportProvider>
    ),
    benchmarkAssessmentReportContainer,
  );
};

export default BenchmarkAssessmentReportApp;

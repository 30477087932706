import React from 'react';
import ReactDOM from 'react-dom';
import { CommonAppProps } from '@constants/Common';
import EndOfYearReportProvider from '@providers/EndOfYearReportProvider';
import getAppInfo from '@helpers/common/App';

const EndOfYearReportChunk = () => import('../pages/reports/end-of-year/Container');
export interface EndOfYearReportHeader {
  question: string;
  name: string;
  description: string;
}
export interface EndOfYearReportAppProps extends CommonAppProps{
  reportHeader: EndOfYearReportHeader;
}

export type EndOfYearReportAppFn = (selector: string, props: EndOfYearReportAppProps) => void;

const EndOfYearReportApp = async (selector: string, props: EndOfYearReportAppProps) => {
  const { default: EndOfYearReportInstance } = await EndOfYearReportChunk();
  const endOfYearReportContainer = document.getElementById(selector);
  if (!endOfYearReportContainer) {
    // eslint-disable-next-line no-console
    console.error(
      'App Unable to mount End of year report application to selector: ',
      selector,
    );
    return;
  }
  const { userInfo, REPORTING_API_URL } = getAppInfo(props);

  ReactDOM.render(
    (
      <EndOfYearReportProvider
        token={props.token}
        language="en"
        userInfo={userInfo}
        reportingApiURL={REPORTING_API_URL}
        updateSADashboardSelectedClassGroup={props.updateSADashboardSelectedClassGroup}
        reportHeader={props.reportHeader}
      >
        <EndOfYearReportInstance />
      </EndOfYearReportProvider>
    ),
    endOfYearReportContainer,
  );
};

export default EndOfYearReportApp;

import React from 'react';
import { IntlProvider } from 'react-intl';
import AppMessages from '@components/AppMessages';

export type LanguageCodes = 'en';
interface TranslationProviderProps {
  children: React.ReactNode;
  language: LanguageCodes;
}
const TranslationProvider = (props: TranslationProviderProps) => {
  const { children, language } = props;
  const translations = AppMessages[language];
  return (
    <IntlProvider
      locale={language}
      messages={translations}
    >
      {children}
    </IntlProvider>
  );
};

export default TranslationProvider;

import React from 'react';
import ReactDOM from 'react-dom';
import { CommonAppProps } from '@constants/Common';
import ManageClassProvider from '@providers/ManageClassProvider';
import getAppInfo from '@helpers/common/App';

const ManageClassChunk = () => import('../pages/manage-class/Container');
export interface ManageClassHeader {
  title: string;
}
export interface ManageClassAppProps extends CommonAppProps {
  pageHeader: ManageClassHeader;
}

export type ManageClassAppFn = (selector: string, props: ManageClassAppProps) => void;

const ManageClassApp = async (selector: string, props: ManageClassAppProps) => {
  const { default: ManageClassInstance } = await ManageClassChunk();
  const manageClassContainer = document.getElementById(selector);
  if (!manageClassContainer) {
    console.error(
      'App Unable to mount manage class application to selector: ',
      selector,
    );
    return;
  }
  const { userInfo, REPORTING_API_URL } = getAppInfo(props);

  ReactDOM.render(
    (
      <ManageClassProvider
        token={props.token}
        language="en"
        userInfo={userInfo}
        reportingApiURL={REPORTING_API_URL}
        pageHeader={props.pageHeader}
      >
        <ManageClassInstance />
      </ManageClassProvider>
    ),
    manageClassContainer,
  );
};

export default ManageClassApp;

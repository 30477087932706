import { asNumberOrNull } from '@helpers/common/Common';
import { UserInfo } from '@redux/slices/UserSlice';
import { DataCenterAppProps } from '../../app/DataCenterApp';
import { EndOfYearReportAppProps } from '../../app/EndOfYearReportApp';
import { ManageClassAppProps } from '../../app/ManageClassApp';
import { BenchmarkAssessmentReportAppProps } from '../../app/BenchmarkAssessmentReportApp';

type AppProps = EndOfYearReportAppProps | DataCenterAppProps | ManageClassAppProps | BenchmarkAssessmentReportAppProps;

const getAppInfo = (props: AppProps) => {
  const { REPORTING_API_URL = '', SA_URL = '' } = process.env;

  const userInfo: UserInfo = {
    classId: asNumberOrNull(props.classId),
    districtId: asNumberOrNull(props.districtId),
    districtName: props.districtName,
    groupId: asNumberOrNull(props.groupId),
    schoolId: asNumberOrNull(props.schoolId),
    schoolName: props.schoolName,
    firstName: props.firstName,
    lastName: props.lastName,
    userId: asNumberOrNull(props.userId),
    userType: props.userType,
    saUrl: SA_URL,
  };

  return {
    userInfo,
    REPORTING_API_URL,
  }
}

export default getAppInfo;

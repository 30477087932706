import { SortDirection } from '@constants/Common';
import { Curriculum, LessonLevel, StatusAndLessonLevel } from '@constants/Curriculum';
import { IRowData } from '@achieve-3000/a3k-react-ui';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LevelSetStatus } from '@constants/LevelSet';

export const END_OF_GRADE_GOAL = 'endOfGradeGoal';
export const ONE_ACADEMIC_YEAR_GOAL = 'academicYearGoal';
export type EndOfGradeGoalHeaderType = 'endOfGradeGoal' | 'academicYearGoal';

export interface AggregatedStatus {
  completed: number;
  total: number;
}
export interface ClassOrStudent extends IRowData {
  id: number;
  externalStudentId: string;
  name: string;
  curriculum: Curriculum;
  startOfYearPlacement: StatusAndLessonLevel | AggregatedStatus;
  midYearPlacement: StatusAndLessonLevel | AggregatedStatus;
  endOfYearPlacement: StatusAndLessonLevel | AggregatedStatus;
  endOfGradeGoal: LessonLevel | null;
  academicYearGoal: LessonLevel | null;
  completedCurriculum: boolean;
  endOfYearLexile: number | null;
  endOfYearStatus: LevelSetStatus | AggregatedStatus | null;
  endOfYearScore: number | null;
  printConcepts: any | null;
  alphabetKnowledge: any | null;
  phonics: any | null;
  wordIdentification: any | null;
  twoSentence: any | null;
  native: any | null;
  endOfYearTimeSpent: number | null;
  dateCompleted: string | null;
  totalTimePlayed: number | null;
  isClassOrGroup: boolean;
}

export interface StudentsState {
  students: ClassOrStudent[];
  selectedBenchmarkAssessmentGoalLesson: EndOfGradeGoalHeaderType;
  sortBy: string;
  sortDirection: SortDirection;
  dateTimeLoaded: Date;
}

export interface SetStudentsAction {
  students: ClassOrStudent[];
  classOrGroup: ClassOrStudent | null;
  dateTimeLoaded: Date;
}

const setStudentsReducer = (state: StudentsState, { payload }: PayloadAction<SetStudentsAction>) => {
  state.students = payload.students;
  state.dateTimeLoaded = payload.dateTimeLoaded;
}

export interface SetStudentSortingAction {
  sortBy: string;
  sortDirection: SortDirection;
}

const setStudentSortingReducer = (state: StudentsState, { payload }: PayloadAction<SetStudentSortingAction>) => {
  state.sortBy = payload.sortBy;
  state.sortDirection = payload.sortDirection;
}

export interface ChangeSelectBenchmarkAssessmentGoalLessonStateAction {
  selectedBenchmarkAssessmentGoalLesson: EndOfGradeGoalHeaderType;
}

const setBenchmarkAssessmentGoalLessonReducer = (
  state: StudentsState, { payload }: PayloadAction<ChangeSelectBenchmarkAssessmentGoalLessonStateAction>,
) => {
  state.selectedBenchmarkAssessmentGoalLesson = payload.selectedBenchmarkAssessmentGoalLesson;
}

const initialStudentsState: StudentsState = {
  students: [],
  selectedBenchmarkAssessmentGoalLesson: END_OF_GRADE_GOAL,
  sortBy: 'name',
  sortDirection: 'asc',
  dateTimeLoaded: new Date(),
}

export const studentsSlice = createSlice({
  name: 'benchmark-assessment-report-students',
  initialState: initialStudentsState,
  reducers: {
    setStudents: setStudentsReducer,
    setBenchmarkAssessmentGoalLesson: setBenchmarkAssessmentGoalLessonReducer,
    setStudentSorting: setStudentSortingReducer,
  },
});

import React from 'react';
import { StyledEngineProvider } from '@mui/material';
import { StylesProvider, createGenerateClassName } from '@mui/styles';

import { ThemeProvider, createTheme } from '@mui/material/styles';

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

const outlineStyle = {
  outline: '#267FBC solid 2px',
  outlineOffset: '4px',
};

const generateClassName = createGenerateClassName({
  productionPrefix: 'sa',
});

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#3F51AE',
    },
    text: {
      primary: '#000',
      secondary: '#545454',
    },
  },
  typography: {
    fontFamily:
      '"Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    body1: {
      letterSpacing: 0,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            ...outlineStyle,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            ...outlineStyle,
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#000',
          marginBottom: '8px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '14px',
          fontWeight: 'bold',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '14px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            outline: 'none',
          },
          '&.Mui-focusVisible': {
            ...outlineStyle,
            outlineOffset: '-2px',
            borderRadius: '0px',
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiSelect-select:focus': outlineStyle,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        color: 'primary',
      },
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': outlineStyle,
        },
        underlineHover: {
          fontFamily: '"Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '& .fa-question-circle:focus': outlineStyle,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          placement: 'bottom',
          fontWeight: 'normal',
        },
      },
    },
    // SA Dashboard have global css overrides that mess up the styles in SA React UI
    // so we have to reset them - RPTNG-6937
    MuiTable: {
      styleOverrides: {
        root: {
          '& button.MuiButtonBase-root': {
            background: 'none',
            border: 'none',
          },
        },
      },
    },
  },
});

interface ThemeProviderProps {
  children: React.ReactNode;
}

const UIThemeProvider = (props: ThemeProviderProps) => {
  const { children } = props;
  return (
    <StylesProvider generateClassName={generateClassName}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  );
};

export default UIThemeProvider;

/* eslint-disable max-len */
// Currently SA do not use any internalization.
// This is added in case another language will be added in the future.
const AppMessages = {
  en: {
    'benchmarkAssessment.header.alphabetKnowledgeDescriptionMessage': 'Student performance on questions pertaining to letter knowledge, both upper and lower case.',
    'benchmarkAssessment.header.alphabetKnowledgeNoDataMessage': '“No Data” means the student has not yet completed the Level Set test.',
    'benchmarkAssessment.header.alphabetKnowledgeNotTestedMessage': '“Not Tested” means there were no questions of this type on the students test.',
    'benchmarkAssessment.header.beginningOfYearLexileMessage': 'The Lexile® Reading Measure student received from the Beginning of Year LevelSet exam. “No Data” means the student has not yet completed the test.',
    'benchmarkAssessment.header.beginningOfYearLexileUrlMessage': 'Interpreting Students\' Lexiles',
    'benchmarkAssessment.header.beginningOfYearLexileMessageSAE': 'Spanish Lexile® Reading Measure are Coming Soon!  “No Data” means the student has not yet completed the test.',
    'benchmarkAssessment.header.beginningOfYearPlacementDataMessage': 'Student placement in the student program based on results of Beginning of Year placement test ',
    'benchmarkAssessment.header.beginningOfYearPlacementInProgressMessage': '“In Progress” means the student is currently working on the Beginning of Year placement test',
    'benchmarkAssessment.header.beginningOfYearPlacementMessage': 'An asterisk after the lesson and level names means the student was not eligible for the Beginning of Year placement test (either they took another placement test within 45 days of the beginning of year activation or they only played in lessons 70 and above in the English program).  The information shown here indicates placement in the program as of the date the Beginning of Year placement was activated for the school.',
    'benchmarkAssessment.header.beginningOfYearPlacementNoDataMessage': '“No Data” means the student has not yet started the Beginning of Year placement test.',
    'benchmarkAssessment.header.beginningOfYearRemediationMessage': '“No Data” means the student has not yet started the Beginning of Year placement test. Number of triggered remediation lessons is Coming Soon!',
    'benchmarkAssessment.header.beginningOfYearRemediationMessageSAE': 'Smarty Ants Español does not currently have remediation lessons.',
    'benchmarkAssessment.header.beginningOfYearScoreCategoryClickMessage': 'Click on the toggle to expand the table to show specific test category performance.',
    'benchmarkAssessment.header.beginningOfYearScoreUrlMessage': 'Understanding Percentile Scores',
    'benchmarkAssessment.header.beginningOfYearScoreMessage': 'Percent score student received from the Beginning of Year LevelSet benchmark assessment test. “No Data” means the student has not yet completed the Beginning of Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.beginningOfYearScoreMessageSAE': 'Percent score student received from the Beginning of Year LevelSet benchmark assessment test. “No Data” means the student has not yet completed the Beginning of Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.beginningOfYearStatusCompletedMessage': '“Completed MM/DD/YY” means the student has completed the Beginning of Year LevelSet benchmark assessment test and the date it was completed.',
    'benchmarkAssessment.header.beginningOfYearStatusInProgressMessage': '“In Progress” means the student is currently working on the Beginning of Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.beginningOfYearStatusNoDateMessage': '“No Data” means the student has not yet started the Beginning of Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.beginningOfYearTimeSpentMessage': 'Total time spent in the Beginning of Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.beginningOfYearTimeSpentMessageSAE': 'Total time spent in the Beginning of Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.endOfYearLexileMessage': 'The Lexile® Reading Measure student received from the End of Year LevelSet exam. “No Data” means the student has not yet completed the test.',
    'benchmarkAssessment.header.endOfYearLexileUrlMessage': 'Interpreting Students\' Lexiles',
    'benchmarkAssessment.header.endOfYearLexileMessageSAE': 'Spanish Lexile® Reading Measure are Coming Soon!  “No Data” means the student has not yet completed the test.',
    'benchmarkAssessment.header.endOfYearPlacementDataMessage': 'Student placement in the student program based on results of End of Year placement test',
    'benchmarkAssessment.header.endOfYearPlacementInProgressMessage': '“In Progress” means the student is currently working on the End of Year placement test.',
    'benchmarkAssessment.header.endOfYearPlacementMessage': 'An asterisk after the lesson and level names means the student was not eligible for the End of Year placement test (either they took another placement test within 45 days of the end of year activation or they only played in lessons 70 and above in the English program).  The information shown here indicates placement in the program as of the date the End of Year placement was activated for the school.',
    'benchmarkAssessment.header.endOfYearPlacementNoDataMessage': '“No Data” means the student has not yet started the End of Year placement test.',
    'benchmarkAssessment.header.endOfYearRemediationMessage': '“No Data” means the student has not yet started the End of Year placement test. Number of triggered remediation lessons is Coming Soon!',
    'benchmarkAssessment.header.endOfYearRemediationMessageSAE': 'Smarty Ants Español does not currently have remediation lessons.',
    'benchmarkAssessment.header.endOfYearScoreCategoryClickMessage': 'Click on the toggle to expand the table to show specific test category performance.',
    'benchmarkAssessment.header.endOfYearScoreUrlMessage': 'Understanding Percentile Scores',
    'benchmarkAssessment.header.endOfYearScoreMessage': 'Percent score student received from the End of Year LevelSet benchmark assessment test. “No Data” means the student has not yet completed the End of Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.endOfYearScoreMessageSAE': 'Percent score student received from the End of Year LevelSet benchmark assessment test. “No Data” means the student has not yet completed the End of Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.endOfYearStatusCompletedMessage': '“Completed MM/DD/YY” means the student has completed the End of Year LevelSet benchmark assessment test and the date it was completed.',
    'benchmarkAssessment.header.endOfYearStatusInProgressMessage': '“In Progress” means the student is currently working on the End of Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.endOfYearStatusNoDateMessage': '“No Data” means the student has not yet started the End of Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.endOfYearTimeSpentMessage': 'Total time spent in the End of Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.endOfYearTimeSpentMessageSAE': 'Total time spent in the End of Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.midYearLexileMessage': 'The Lexile® Reading Measure student received from the Mid-Year LevelSet exam.  “No Data” means the student has not yet completed the test.',
    'benchmarkAssessment.header.midYearLexileUrlMessage': 'Interpreting Students\' Lexiles',
    'benchmarkAssessment.header.midYearLexileMessageSAE': 'Spanish Lexile® Reading Measure are Coming Soon!  “No Data” means the student has not yet completed the test.',
    'benchmarkAssessment.header.midYearPlacementDataMessage': 'Student placement in the student program based on results of Mid-Year placement test',
    'benchmarkAssessment.header.midYearPlacementInProgressMessage': '“In Progress” means the student is currently working on the Mid-Year placement test.',
    'benchmarkAssessment.header.midYearPlacementMessage': 'An asterisk after the lesson and level names means the student was not eligible for the Mid-Year placement test (either they took another placement test within 45 days of the Mid-Year activation or they only played in lessons 70 and above in the English program).  The information shown here indicates placement in the program as of the date the Mid-Year placement was activated for the school.',
    'benchmarkAssessment.header.midYearPlacementNoDataMessage': '“No Data” means the student has not yet started the Mid-Year placement test.',
    'benchmarkAssessment.header.midYearRemediationMessage': '“No Data” means the student has not yet started the Mid-Year placement test. Number of triggered remediation lessons is Coming Soon!',
    'benchmarkAssessment.header.midYearRemediationMessageSAE': 'Smarty Ants Español does not currently have remediation lessons.',
    'benchmarkAssessment.header.midYearScoreCategoryClickMessage': 'Click on the toggle to expand the table to show specific test category performance.',
    'benchmarkAssessment.header.midYearScoreUrlMessage': 'Understanding Percentile Scores',
    'benchmarkAssessment.header.midYearScoreMessage': 'Percent score student received from the Mid-Year LevelSet benchmark assessment test. “No Data” means the student has not yet completed the Mid-Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.midYearScoreMessageSAE': 'Percent score student received from the Mid-Year LevelSet benchmark assessment test. “No Data” means the student has not yet completed the Mid-Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.midYearStatusCompletedMessage': '“Completed MM/DD/YY” means the student has completed the Mid-Year LevelSet benchmark assessment test and the date it was completed.',
    'benchmarkAssessment.header.midYearStatusInProgressMessage': '“In Progress” means the student is currently working on the Mid-Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.midYearStatusNoDateMessage': '“No Data” means the student has not yet started the Mid-Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.midYearTimeSpentMessage': 'Total time spent in the Mid-Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.midYearTimeSpentMessageSAE': 'Total time spent in the Mid-Year LevelSet benchmark assessment test.',
    'benchmarkAssessment.header.nativeDescriptionMessage': 'Student performance on questions pertaining to comprehension of paragraphs.',
    'benchmarkAssessment.header.nativeNoDataMessage': '“No Data” means the student has not yet completed the Level Set test.',
    'benchmarkAssessment.header.nativeNotTestedMessage': '“Not Tested” means there were no questions of this type on the students test.',
    'benchmarkAssessment.header.phonemicAwarenessDescriptionMessage': 'Student performance on questions pertaining to phonemic awareness.',
    'benchmarkAssessment.header.phonemicAwarenessNoDataMessage': '“No Data” means the student has not yet completed the Level Set test.',
    'benchmarkAssessment.header.phonemicAwarenessNotTestedMessage': '“Not Tested” means there were no questions of this type on the students test.',
    'benchmarkAssessment.header.phonicsDescriptionMessage': 'Student performance on questions pertaining to phonics.',
    'benchmarkAssessment.header.phonicsNoDataMessage': '“No Data” means the student has not yet completed the Level Set test.',
    'benchmarkAssessment.header.phonicsNotTestedMessage': '“Not Tested” means there were no questions of this type on the students test.',
    'benchmarkAssessment.header.phonologicalAwarenessDescriptionMessage': 'Student performance on questions pertaining to phonological awareness.',
    'benchmarkAssessment.header.phonologicalAwarenessNoDataMessage': '“No Data” means the student has not yet completed the Level Set test.',
    'benchmarkAssessment.header.phonologicalAwarenessNotTestedMessage': '“Not Tested” means there were no questions of this type on the students test.',
    'benchmarkAssessment.header.printConceptsDescriptionMessage': 'Student performance on questions pertaining to Print Concepts like punctuation and book formatting.',
    'benchmarkAssessment.header.printConceptsNoDataMessage': '“No Data” means the student has not yet completed the Level Set test.',
    'benchmarkAssessment.header.printConceptsNotTestedMessage': '“Not Tested” means there were no questions of this type on the students test.',
    'benchmarkAssessment.header.totalTimePlayedMessage': 'Total time spent by the student in the Smarty Ants program in the current school year through today.',
    'benchmarkAssessment.header.totalTimePlayedMessageSAE': 'Total time spent by the student in the Smarty Ants Español program in the current school year through today.',
    'benchmarkAssessment.header.twoSentenceDescriptionMessage': 'Student performance on questions pertaining to comprehension of sentences.',
    'benchmarkAssessment.header.twoSentenceNoDataMessage': '“No Data” means the student has not yet completed the Level Set test.',
    'benchmarkAssessment.header.twoSentenceNotTestedMessage': '“Not Tested” means there were no questions of this type on the students test.',
    'benchmarkAssessment.header.wordIdentificationDescriptionMessage': 'Student performance on questions pertaining to identifying the correct word for a given picture.',
    'benchmarkAssessment.header.wordIdentificationNoDataMessage': '“No Data” means the student has not yet completed the Level Set test.',
    'benchmarkAssessment.header.wordIdentificationNotTestedMessage': '“Not Tested” means there were no questions of this type on the students test.',
    'benchmarkAssessment.header.beginningOfYearLexileTooltip': 'Read more about Beginning of Year Lexile',
    'benchmarkAssessment.header.beginningOfYearPlacementTooltip': 'Read more about Beginning of Year Placement',
    'benchmarkAssessment.header.beginningOfYearScoreTooltip': 'Read more about Beginning of Year Score',
    'benchmarkAssessment.header.beginningOfYearStatusTooltip': 'Read more about Beginning of Year Status',
    'benchmarkAssessment.header.beginningOfYearTimeSpentTooltip': 'Read more about Beginning of Year Time Spent',
    'benchmarkAssessment.header.totalTimePlayedTooltip': 'Read more about Total Time Played',
    selectGoalTypeMenuTooltip: 'Read more about Goal Type',
    'datacenter.header.currentLessonTooltip': 'Read more about Current Lesson',
    'datacenter.header.feedbackModalTooltip': 'Read more about Tell us what you think',
    'datacenter.header.goalLessonHeaderLabel': 'One Academic Year Goal or End of Grade Level Goal',
    'datacenter.header.goalLessonHeaderModalTooltip': 'Read more about Goals',
    'datacenter.header.goalLessonMessage': 'Select which Academic Goal you\'d like to view progress on for all students.',
    'datacenter.header.recordingBelowMessage': 'below.',
    'datacenter.header.recordingMessage': 'Listen to audio recordings of student\'s oral reading passages by clicking the',
    'datacenter.header.remediationCurrentMessage': 'The presence of this button signifies this student is struggling with some aspect of their current lesson.',
    'datacenter.header.remediationHelpLink': 'Help Link to In-Depth Progress Report',
    'datacenter.header.remediationInDepthMessage': 'Click the button to go to the In Depth Progress report to review their results and access remediation assets available.',
    'datacenter.header.remediationModalTooltip': 'Read more about Remediation and the Help link',
    'datacenter.header.selectAllStudentsLabel': 'Select all students',
    'datacenter.header.selectGoalLessonTooltip': 'Select goal type menu',
    'datacenter.header.selectStudentLabel': 'Select {studentName}',
    'datacenter.header.sortCurrentLessonTooltip': 'Sort by current lesson',
    'datacenter.header.sortGoalLevelLessonTooltip': 'Sort by goal level and lesson',
    'datacenter.header.sortHelpButtonTooltip': 'Sort by Remediation and the help link',
    'datacenter.header.sortLastLoginTooltip': 'Sort by last login',
    'datacenter.header.sortStudentNameTooltip': 'Sort by student name',
    'datacenter.header.sortTimeSpentTooltip': 'Sort by time spent this week',
    'datacenter.header.studentLessonClickMessage': 'Click',
    'datacenter.header.studentLessonExpectedMessage': 'to expand student row to see their "Expected" level and lesson for the selected academic goal.',
    'datacenter.header.studentLessonFallingBehind': 'Falling Behind',
    'datacenter.header.studentLessonNeedsHelp': 'Needs Help',
    'datacenter.header.studentLessonOnTrack': 'On Track',
    'datacenter.header.studentLessonProgressMessage': 'The stop light colors indicate the progress between a student\'s current lesson/level and their expected lesson/level.',
    'datacenter.header.studentNameModalAriaLabel': 'Listen to audio recordings of student\'s oral reading passages by clicking the recording icon below',
    'datacenter.header.studentNameTooltip': 'Read more about Student Name',
    'datacenter.header.timeSpentLabel': '{timeSpent} link',
    'datacenter.header.timeSpentModalTooltip': 'Read more about time spent this week',
    'datacenter.header.timeSpentThisWeekMessage': 'Time students have spent in the program this week, from Sunday through today.',
    'datacenter.table.noTableRowsMessage': 'No data available in table',
    'datacenter.table.notYetPlacedTooltip': 'Read more about students who have not yet placed',
    'endOfYear.csv.asteriskExplanation': 'If a student did not take a particular assessment, it shows the level and lesson they were in when that assessment was triggered for their school, followed by an asterisk "*".',
    'endOfYear.header.endOfGradeGoalMessage': 'Select which Academic Goal you\'d like to view progress on for all students.',
    'endOfYear.header.endOfYearPlacementMessage': 'This column shows the level and lesson a student placed into when they took the End of Year Placement.  Only students who made progress in levels 1-11 during the current school year and have not completed another assessment in the last 45 days will be eligible for the End of Year Assessment. If a student is not eligible, it shows the level and lesson they were in when that assessment was triggered for their school, followed by an asterisk "*".',
    'endOfYear.header.endOfYearPlacementMessageSAE': 'This column shows the level and lesson a student placed into when they took the End of Year Placement. If a student is not eligible, it shows the level and lesson they were in when that assessment was triggered for their school, followed by an asterisk "*".',
    'endOfYear.header.goalProgressStatusMessage': 'This column shows the progress status for the student when comparing their End of year placement with the Academic Goal.',
    'endOfYear.header.goalStatusMessageApproaching': 'This student is approaching the expected progress threshold to meet their Academic Goal.',
    'endOfYear.header.goalStatusMessageExceeds': 'This student has exceeded the expected progress towards their Academic Goal.',
    'endOfYear.header.goalStatusMessageFallsBelow': 'This student has fallen far below the expected progress to meet their Academic Goal.',
    'endOfYear.header.goalStatusMessageMeets': ' This student has met their expected progress towards their Academic Goal.',
    'endOfYear.header.initialPlacementNoDataMessage': '“No Data” means the student has not yet started the Initial Placement test.',
    'endOfYear.header.initialPlacementInProgressMessage': '“In Progress” means the student is currently working on the Initial Placement test',
    'endOfYear.header.initialPlacementDataMessage': 'Student placement in the student program based on results of Initial Placement test ',
    'endOfYear.header.initialPlacementMessage': 'An asterisk after the lesson and level names means the student was not eligible for the Initial Placement test (either they took another placement test within 45 days of the beginning of year activation or they only played in lessons 70 and above in the English program).  The information shown here indicates placement in the program as of the date the Initial Placement was activated for the school.',
    'endOfYear.header.midYearPlacementMessage': 'This column shows the level and lesson a student placed into when they took the MidYear Assessment.  If they did not take a MidYear assessment, it shows the level and lesson they were in when that assessment was triggered for their school, followed by an asterisk "*".',
    'endOfYear.header.midYearPlacementMessageSAE': 'This column shows the level and lesson a student was in at the mid year point in the current school year, followed by an asterisk "*".',
    'endOfYear.header.placementBeforeEndOfYearMessage': 'This column shows the level and lesson a student was in when the End of Year Placement was turned on for their school.',
    'endOfYear.header.sortStudentNameTooltip': 'Sort by student name',
    'endOfYear.header.sortInitialPlacementTooltip': 'Sort by initial placement',
    'endOfYear.header.sortMidYearPlacementTooltip': 'Sort by mid year placement',
    'endOfYear.header.sortEndOfYearPlacementTooltip': 'Sort by end of year placement',
    'endOfYear.header.sortEndOfGradeGoalTooltip': 'Sort by end of grade goal',
    'endOfYear.header.sortGoalProgressStatusTooltip': 'Sort by goal progress status',
    'endOfYear.header.sortAcademicYearGoalTooltip': 'Sort by academic year goal',
    alphabetKnowledge: 'Alphabet Knowledge',
    apply: 'Apply',
    approaching: 'Approaching',
    benchmarkReportEndOfYearPlacement: 'End of Year Placement',
    benchmarkReportBeginningOfYearPlacement: 'Beginning of Year Placement',
    benchmarkReportMidYearPlacement: 'Mid-Year Placement',
    cancel: 'Cancel',
    class: 'Class',
    classAndTeacher: 'Class & Teacher',
    classes: 'Classes',
    classesAndGroups: 'Classes & Groups',
    classOrGroup: 'Class/Group',
    comingSoon: 'Coming Soon',
    completed: 'Completed',
    completedCurriculum: 'Completed Curriculum',
    createClass: 'Create Class',
    currentLesson: 'Current Lesson',
    currentLevel: 'Current Level',
    district: 'District',
    editGroup: 'Edit Group',
    beginningOfYearLexile: 'Beginning of Year Lexile',
    beginningOfYearPlacement: 'Beginning Of Year Placement',
    beginningOfYear: 'Beginning Of Year',
    beginningOfYearPlacementLesson: 'Beginning Of Year Placement Lesson',
    beginningOfYearPlacementLevel: 'Beginning Of Year Placement Level',
    beginningOfYearScore: 'Beginning of Year Score',
    beginningOfYearStatus: 'Beginning of Year Status',
    beginningOfYearTimeSpent: 'Beginning of Year Time Spent',
    endOfGradeGoal: 'End of Grade Goal',
    endOfGradeGoalLesson: 'End of Grade Goal Lesson',
    endOfGradeGoalLevel: 'End of Grade Goal Level',
    endOfGradeLevel: 'End of Grade Level',
    endOfYearLexile: 'End of Year Lexile',
    endOfYearPlacement: 'End of Year Placement',
    endOfYearPlacementLesson: 'End of Year Placement Lesson',
    endOfYearPlacementLevel: 'End of Year Placement Level',
    endOfYearScore: 'End of Year Score',
    endOfYearStatus: 'End of Year Status',
    endOfYearTimeSpent: 'End of Year Time Spent',
    exceeds: 'Exceeds',
    expectedLesson: 'Expected Lesson',
    expectedLessonToggleExpandedTooltip: 'Expected level and lesson : expanded',
    expectedLessonToggleUnexpandedTooltip: 'Expected level and lesson : unexpanded',
    expectedLevel: 'Expected Level',
    exportCSVTooltip: 'Export to .csv',
    fallsFarBelow: 'Falls Far Below',
    feedbackMessage: 'Please use this to provide us with your thoughts and suggestions about the new Smarty Ants Data Center page.  If you have questions or problems with the page, please contact customer support (support@achieve3000.com) for assistance.',
    fetchClassData: 'Fetch Class Data',
    filterListTooltip: 'Select your class/group menu',
    benchmarkLexileFooter: 'LEXILE® is a trademark of MetaMetrics, Inc. and registered in the United States and abroad',
    benchmarkCopyrightFooter: 'Copyright © {date} MetaMetrics, Inc. All rights reserved.',
    placementYearfilterListTooltip: 'Select your Placement Year menu',
    goalLesson: 'Goal Lesson',
    goalLevel: 'Goal Level',
    goalProgressStatus: 'Goal Progress Status',
    groups: 'Groups',
    help: 'Help!',
    initialPlacement: 'Initial Placement',
    initialPlacementLesson: 'Initial Placement Lesson',
    initialPlacementLevel: 'Initial Placement Level',
    inProgress: 'In Progress',
    lastLoginDate: 'Last Login',
    lastUpdatedOn: 'Last updated on',
    lesson: 'Lesson',
    level: 'Level',
    levelsetAssessment: 'LevelSet Assessment',
    link: 'link',
    loading: 'Loading',
    makeGroup: 'Make Group',
    manualSetupTooltipMessage: 'If you want to create both a Smarty Ants and a Smarty Ants Espanol class, please use the import option or send to our internal Rostering team.',
    meets: 'Meets',
    midYear: 'Mid-Year',
    midYearLexile: 'Mid-Year Lexile',
    midYearPlacement: 'Mid Year Placement',
    midYearPlacementLesson: 'Mid Year Placement Lesson',
    midYearPlacementLevel: 'Mid Year Placement Level',
    midYearRemediation: 'Mid-Year Remediation',
    midYearScore: 'Mid-Year Score',
    midYearStatus: 'Mid-Year Status',
    midYearTimeSpent: 'Mid-Year Time Spent',
    native: 'Paragraph Comprehension',
    no: 'No',
    noData: 'No Data',
    noTeacherAssigned: 'No Teacher Assigned',
    notTested: 'Not Tested',
    notYetLoggedIn: 'This student has not yet logged into the Smarty Ants program for the current school year.',
    notYetLoggedInEspanol: 'This student has not yet logged into the Smarty Ants (Espanol) program for the current school year.',
    notYetPlaced: 'Not Yet Placed',
    notYetPlacedWithBTS: 'This student has not yet completed their initial/BTS assessment for the current school year.',
    notYetPlacedWithoutBTS: 'This student has not logged in or has not started a new lesson in the current school year.',
    notYetStarted: 'Not Yet Started',
    oneAcademicYearGoal: '1 Academic Year Goal',
    oneAcademicYearGoalLesson: 'One Academic Year Goal Lesson',
    oneAcademicYearGoalLevel: 'One Academic Year Goal Level',
    phonemicAwareness: 'Phonemic Awareness',
    phonics: 'Phonics',
    phonologicalAwareness: 'Phonological Awareness',
    printButtonTooltip: 'Print',
    printConcepts: 'Print Concepts',
    printedBy: 'Printed By',
    printedOn: 'Printed On',
    readRecordingTooltip: 'Listen to student recordings',
    remediation: 'Remediation',
    school: 'School',
    smartyantsPlacement: 'Smarty Ants Placement',
    student: 'Student',
    studentId: 'Student Id',
    studentName: 'Student Name',
    students: 'Students',
    tellUsWhatYouThink: 'Tell us what you think!',
    timeSpentThisWeek: 'Time Spent This Week',
    timeSpentThisWeekTooltip: 'Time spent',
    totalTimePlayed: 'Total Time Played',
    twoSentence: 'Sentence Comprehension',
    unreadRecordingTooltip: 'Student has a new recording',
    wordIdentification: 'Word Identification',
    yes: 'Yes',
  },
};

export default AppMessages;

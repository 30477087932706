import { IStatusLabelData } from '@achieve-3000/a3k-react-ui';
import {
  LessonLevel,
  LessonLevelResult,
  LessonLevelStatusResult,
  StatusAndLessonLevel,
} from '@constants/Curriculum';
import { IntlShape } from 'react-intl';

export type NullOrUndefined = null | undefined;

export const asNumberOrZero = (value: number | null | undefined) => ((value === null || value === undefined) ? 0 : value);
export const asStringOrEmpty = (value: string | null | undefined) => value || '';

export const asNumberOrNull = (value: string | number | null | undefined) => {
  if (!value) {
    return null;
  }
  return +value;
};

export const isNullOrUndefined = (val: any) => {
  if (val === undefined || val === null) {
    return true;
  }
  return false;
};

export const EMPTY_STRING = '\xa0';

export const getStatusLabelData = (levelLabel: string, lessonLabel: string, lesson: LessonLevel | null): IStatusLabelData[] => [
  {
    leftLabel: levelLabel,
    rightValue: lesson?.levelName || '',
  },
  {
    leftLabel: lessonLabel,
    rightValue: lesson?.lessonName || '',
  },
];

export const getStudentName = (firstName: string, lastName: string) => {
  let name = lastName;
  name = (name && firstName) ? `${name}, ` : name;
  name = `${name}${firstName}`;

  return name;
};

export const getLevelLessonName = (
  levelLessonNumber: number | null,
  levelLessonName: string | undefined | null,
  withAsterisk?: boolean,
) => {
  let value = EMPTY_STRING;
  const goalLevelLessonNumber = !isNullOrUndefined(levelLessonNumber) ? levelLessonNumber : '';
  const goalLevelLessonName = !isNullOrUndefined(levelLessonName) ? levelLessonName : '';
  if (goalLevelLessonNumber || (goalLevelLessonNumber && goalLevelLessonName)) {
    value = `${goalLevelLessonNumber} ${goalLevelLessonName}${withAsterisk ? ' *' : ''}`;
  }
  return value;
}

export const getLessonLevel = (
  lessonLevelResult: LessonLevelResult | null,
  withAsterisk?: boolean,
) => {
  if (!lessonLevelResult) {
    return null;
  }
  const { level, lesson } = lessonLevelResult;
  const { number: levelNumber, name: levelName } = level || {};
  const { number: lessonNumber, name: lessonName } = lesson || {};

  if (!levelNumber && !levelName && !lessonNumber && !lessonName) {
    return null;
  }

  const lessonLevel: LessonLevel = {
    levelNumber: asNumberOrNull(levelNumber),
    levelName: getLevelLessonName(levelNumber, levelName, withAsterisk),
    lessonNumber: asNumberOrNull(lessonNumber),
    lessonName: getLevelLessonName(lessonNumber, lessonName, withAsterisk),
  }
  return lessonLevel;
}

export const setLocalStorage = (key: string, value: string) => localStorage.setItem(key, value);

export const getLocalStorage = (key: string) => localStorage.getItem(key);

export const formatLexile = (lexile: string | number | NullOrUndefined, useBR = true) => {
  if (isNullOrUndefined(lexile)) {
    return lexile;
  }

  const lexileNum = lexile as number;
  if (lexileNum < 0 && useBR) {
    return `BR${Math.abs(lexileNum)}L`;
  }
  return `${lexileNum}L`;
}

export const evaluateLessonLevelStatus = (
  status: LessonLevelStatusResult,
  lessonLevel: LessonLevel | null,
  intl: IntlShape,
) => {
  switch (status) {
    case (null || undefined):
      return intl.formatMessage({ id: 'noData' });
    case 'NOT_YET_STARTED':
      return intl.formatMessage({ id: 'notYetStarted' });
    case 'IN_PROGRESS':
      return intl.formatMessage({ id: 'inProgress' });
    case ('NOT_ELIGIBLE' || 'COMPLETED'):
      if (lessonLevel) {
        return lessonLevel
      }
      return intl.formatMessage({ id: 'noData' });
    case 'NO_DATA':
      return intl.formatMessage({ id: 'noData' });
    default:
      break;
  }

  return null;
}

export const getStartOfYearPlacement = (
  startOfYearStatusLessonLevel: StatusAndLessonLevel,
  completedCurriculum: boolean,
  intl: IntlShape,
) => {
  const { lessonLevel, status } = startOfYearStatusLessonLevel;

  if (completedCurriculum && (!startOfYearStatusLessonLevel || status === 'NO_DATA' || status === 'CURRICULUM_COMPLETED')) {
    return intl.formatMessage({ id: 'completedCurriculum' });
  }

  const startOfYearPlacement = evaluateLessonLevelStatus(
    status,
    lessonLevel,
    intl,
  );

  if (startOfYearPlacement) {
    return startOfYearPlacement;
  }

  // fallback if no scenario is satisfied
  if (!lessonLevel) {
    return intl.formatMessage({ id: 'noData' });
  }
  return lessonLevel;
}

export const getMidYearPlacement = (
  midYearStatusLessonLevel: StatusAndLessonLevel,
  completedCurriculum: boolean,
  intl: IntlShape,
) => {
  const { lessonLevel, status } = midYearStatusLessonLevel;

  if (completedCurriculum && (!midYearStatusLessonLevel || status === 'NO_DATA' || status === 'CURRICULUM_COMPLETED')) {
    return intl.formatMessage({ id: 'completedCurriculum' });
  }

  const midYearPlacement = evaluateLessonLevelStatus(
    status,
    lessonLevel,
    intl,
  );

  if (midYearPlacement) {
    return midYearPlacement;
  }

  // fallback if no scenario is satisfied
  if (!lessonLevel) {
    return intl.formatMessage({ id: 'noData' });
  }
  return lessonLevel;
};

export const getLessonName = (placement: string | LessonLevel) => {
  if (typeof placement === 'string') {
    return placement;
  }
  return placement.lessonName;
}

export const getLevelName = (placement: string | LessonLevel) => {
  if (typeof placement === 'string') {
    return placement;
  }
  return placement.levelName;
}

export const round = (value: number, decimalPlaces: number) => {
  const factorOfTen = (10 ** decimalPlaces);
  return Math.round(value * factorOfTen) / factorOfTen;
}

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import DataCenterApp, { DataCenterAppFn } from './DataCenterApp';
import EndOfYearReportApp, { EndOfYearReportAppFn } from './EndOfYearReportApp';
import ManageClassApp, { ManageClassAppFn } from './ManageClassApp';
import BenchmarkAssessmentReportApp, { BenchmarkAssessmentReportAppFn } from './BenchmarkAssessmentReportApp';

interface ReactApps {
  DataCenter: DataCenterAppFn;
  EndOfYearReport: EndOfYearReportAppFn;
  ManageClass: ManageClassAppFn;
  BenchmarkAssessmentReport: BenchmarkAssessmentReportAppFn;
}

export interface ReactAppsNamespace {
  __REACT_APPS_NAMESPACE: ReactApps;
}
// @TODO: Since the apps in SA React UI are still independent, each app is mounted independently.
// Once fully migrated, we can mount a single app and use routes to navigate between features.

(async function createWebappUIHandlers() {
  Object.assign<Window, ReactAppsNamespace>(window, {
    __REACT_APPS_NAMESPACE: {
      DataCenter: DataCenterApp,
      EndOfYearReport: EndOfYearReportApp,
      ManageClass: ManageClassApp,
      BenchmarkAssessmentReport: BenchmarkAssessmentReportApp,
    },
  });
}());

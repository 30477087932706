import React from 'react';
import TranslationProvider, { LanguageCodes } from '@providers/TranslationProvider';
import UIThemeProvider from '@providers/UIThemeProvider';
import StoreProvider from '@providers/StoreProvider';
import DataProvider from '@providers/DataProvider';
import { UserInfo } from '@redux/slices/UserSlice';
import { initialFiltersState, UpdateSADashboardSelectedClassGroupFn } from '@redux/slices/FiltersSlice';
import { EndOfYearReportHeader } from 'app/EndOfYearReportApp';
import { initialSchoolState } from '@redux/slices/SchoolSlice';
import { initialHeaderState } from '@redux/slices/end-of-year-report/HeaderSlice';

interface EndOfYearReportProviderProps {
  children: React.ReactNode;
  language: LanguageCodes;
  userInfo: UserInfo;
  token: string;
  reportingApiURL: string;
  updateSADashboardSelectedClassGroup?: UpdateSADashboardSelectedClassGroupFn;
  reportHeader: EndOfYearReportHeader;
}

const EndOfYearReportProvider = ({
  children,
  language,
  token,
  userInfo,
  reportingApiURL,
  updateSADashboardSelectedClassGroup,
  reportHeader,
}: EndOfYearReportProviderProps) => {
  const preloadedState = {
    user: {
      userInfo,
    },
    school: {
      ...initialSchoolState,
      name: userInfo.schoolName,
    },
    filters: {
      classesAndGroups: {
        ...initialFiltersState.classesAndGroups,
        updateSADashboardSelectedClassGroup,
      },
    },
    endOfYearReport: {
      header: {
        ...initialHeaderState,
        ...reportHeader,
      },
    },

  }
  return (
    <DataProvider
      token={token}
      apiURL={reportingApiURL}
    >
      <StoreProvider preloadedState={preloadedState}>
        <UIThemeProvider>
          <TranslationProvider language={language}>
            {children}
          </TranslationProvider>
        </UIThemeProvider>
      </StoreProvider>
    </DataProvider>
  );
}

export default EndOfYearReportProvider;

import React from 'react';
import TranslationProvider, { LanguageCodes } from '@providers/TranslationProvider';
import UIThemeProvider from '@providers/UIThemeProvider';
import StoreProvider from '@providers/StoreProvider';
import DataProvider from '@providers/DataProvider';
import { UserInfo } from '@redux/slices/UserSlice';
import { ManageClassHeader } from 'app/ManageClassApp';
import { initialSchoolState } from '@redux/slices/SchoolSlice';
import { initialFiltersState } from '@redux/slices/FiltersSlice';
import { initialManageClassHeaderState } from '@redux/slices/manage-class/HeaderSlice';

interface ManageClassProviderProps {
  children: React.ReactNode;
  language: LanguageCodes;
  userInfo: UserInfo;
  token: string;
  reportingApiURL: string;
  pageHeader: ManageClassHeader;
}

const ManageClassProvider = ({
  children,
  language,
  token,
  userInfo,
  reportingApiURL,
  pageHeader,
}: ManageClassProviderProps) => {
  const preloadedState = {
    user: {
      userInfo,
    },
    school: {
      ...initialSchoolState,
      name: userInfo.schoolName,
    },
    filters: {
      classesAndGroups: {
        ...initialFiltersState.classesAndGroups,
      },
    },
    manageClass: {
      header: {
        ...initialManageClassHeaderState,
        ...pageHeader,
      },
    },
  };

  return (
    <DataProvider
      token={token}
      apiURL={reportingApiURL}
    >
      <StoreProvider preloadedState={preloadedState}>
        <UIThemeProvider>
          <TranslationProvider language={language}>
            {children}
          </TranslationProvider>
        </UIThemeProvider>
      </StoreProvider>
    </DataProvider>
  );
}

export default ManageClassProvider;

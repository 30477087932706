import { SortDirection } from '@constants/Common';
import { Curriculum, LessonLevel } from '@constants/Curriculum';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRowData } from '@achieve-3000/a3k-react-ui';
import findKey from 'lodash/findKey';

export type SelectedStudentsMap = {
  [key: number]: boolean;
}

const haveNotSelected = (
  selectedStudents: SelectedStudentsMap,
) => Boolean(findKey(selectedStudents, (isStudentSelected) => !isStudentSelected));

export const END_OF_GRADE_LEVEL = 'endOfGradeLevel';
export const ONE_ACADEMIC_YEAR_GOAL = 'oneAcademicYearGoal';
export type GoalLessonHeaderType = 'oneAcademicYearGoal' | 'endOfGradeLevel';

export interface Student extends IRowData {
  id: number;
  externalStudentId: string;
  studentName: string;
  remediationRecommendationUrl: string | null;
  lastLoginDate: string;
  timeSpentThisWeek: number;
  curriculum: Curriculum;
  completedCurriculum: boolean;
  initialAssessmentRequired: boolean;
  academicYearProgressPercent: number;
  endOfGradeProgressPercent: number;
  currentLessonLevel: LessonLevel | null;
  academicYearGoalLessonLevel: LessonLevel | null;
  endOfGradeGoalLessonLevel: LessonLevel | null;
  academicYearExpectedLessonLevel: LessonLevel | null;
  endOfGradeExpectedLessonLevel: LessonLevel | null;
  lastLogin: Date;
  currentLevelNumber: number;
  currentLevelName: string | null;
  currentLessonNumber: number;
  currentLessonName: string | null;
  academicYearGoalLevelNumber: number;
  academicYearGoalLevelName: string | null;
  academicYearGoalLessonNumber: number;
  academicYearGoalLessonName: string | null;
  endOfGradeGoalLevelNumber: number;
  endOfGradeGoalLevelName: string | null;
  endOfGradeGoalLessonNumber: number;
  endOfGradeGoalLessonName: string | null;
  sortLastLogin: string | null;
  sortCurrentLevelLesson: string | null;
  sortCurrentNotYetPlaced: number;
  sortCompletedCurriculum: string | null;
  sortRemediationRecommendation: string | null;
  sortTimeSpentThisWeek: string | null;
  sortAcademicNotYetPlaced: number;
  sortAcademicGoal: string | null;
  sortEndOfGradeNotYetPlaced: number;
  sortEndOfGradeLevel: string | null;
}

export interface StudentsState {
  students: Student[],
  isAllSelected: boolean;
  selectedStudents: SelectedStudentsMap;
  selectedGoalLesson: GoalLessonHeaderType;
  sortBy: string;
  sortDirection: SortDirection;
}

export interface SetStudentsAction {
  students: Student[];
  selectedStudents: SelectedStudentsMap;
}

const setStudentsReducer = (state: StudentsState, { payload }: PayloadAction<SetStudentsAction>) => {
  state.students = payload.students;
  state.selectedStudents = payload.selectedStudents;
}

export interface SetStudentSortingAction {
  sortBy: string;
  sortDirection: SortDirection;
}

const setStudentSortingReducer = (state: StudentsState, { payload }: PayloadAction<SetStudentSortingAction>) => {
  state.sortBy = payload.sortBy;
  state.sortDirection = payload.sortDirection;
}

export interface SetSelectedStudentsMapAction {
  selectedStudents: SelectedStudentsMap;
}

const setSelectedStudentsMapReducer = (state: StudentsState, { payload }: PayloadAction<SetSelectedStudentsMapAction>) => {
  state.selectedStudents = payload.selectedStudents;
  state.isAllSelected = false;
  state.selectedGoalLesson = ONE_ACADEMIC_YEAR_GOAL;
}

export interface ChangeStudentSelectStateAction {
  studentId: number;
  isSelected: boolean;
}

const changeStudentSelectStateReducer = (state: StudentsState, { payload }: PayloadAction<ChangeStudentSelectStateAction>) => {
  state.selectedStudents[payload.studentId] = payload.isSelected;
  state.isAllSelected = !haveNotSelected(state.selectedStudents);
}

export interface ChangeAllStudentsSelectStateAction {
  isAllSelected: boolean;
}

const changeAllStudentsSelectStateReducer = (state: StudentsState) => {
  const isAllSelected = haveNotSelected(state.selectedStudents);
  Object.keys(state.selectedStudents).forEach((key) => {
    const numKey = parseInt(key, 10);
    state.selectedStudents[numKey] = isAllSelected;
  });
  state.isAllSelected = isAllSelected;
}

export interface ChangeSelectGoalLessonStateAction {
  selectedGoalLesson: GoalLessonHeaderType;
}

const setGoalLessonReducer = (state: StudentsState, { payload }: PayloadAction<ChangeSelectGoalLessonStateAction>) => {
  state.selectedGoalLesson = payload.selectedGoalLesson;
}

const initialStudentsState: StudentsState = {
  students: [],
  isAllSelected: false,
  selectedStudents: {},
  selectedGoalLesson: ONE_ACADEMIC_YEAR_GOAL,
  sortBy: 'studentName',
  sortDirection: 'asc',
}

export const studentsSlice = createSlice({
  name: 'data-center-students',
  initialState: initialStudentsState,
  reducers: {
    setStudents: setStudentsReducer,
    setGoalLesson: setGoalLessonReducer,
    setStudentSorting: setStudentSortingReducer,
    setSelectedStudentsMap: setSelectedStudentsMapReducer,
    changeStudentSelectState: changeStudentSelectStateReducer,
    changeAllStudentsSelectState: changeAllStudentsSelectStateReducer,
  },
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserType } from '@constants/User';

export interface UserInfo {
  userId: number | null;
  classId: number | null;
  districtId: number | null;
  districtName: string;
  groupId: number | null;
  schoolId: number | null;
  schoolName: string;
  firstName: string;
  lastName: string;
  userType: UserType | null;
  saUrl: string;
}

export interface UserState {
  userInfo: UserInfo;
}

export interface SetUserInfoAction {
  userInfo: UserInfo;
}

const setUserInfoReducer = (state: UserState, { payload }: PayloadAction<SetUserInfoAction>) => {
  state.userInfo = payload.userInfo;
}

const initialUserState: UserState = {
  userInfo: {
    userId: null,
    classId: null,
    districtId: null,
    districtName: '',
    groupId: null,
    schoolId: null,
    schoolName: '',
    firstName: '',
    lastName: '',
    userType: null,
    saUrl: '',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUserInfo: setUserInfoReducer,
  },
});

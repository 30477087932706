import React from 'react';
import { Provider } from 'react-redux';
import createStore, { RootState } from '@redux/Store';
import { DeepPartial } from '@reduxjs/toolkit';

interface StoreProviderProps {
  children: React.ReactNode;
  preloadedState: DeepPartial<RootState>,
}

const StoreProvider = (props: StoreProviderProps) => {
  const {
    children,
    preloadedState,
  } = props;

  const store = createStore(preloadedState);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.Cypress && !window.store) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
    window.store = store;
  }

  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
}

export default StoreProvider;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { studentsSlice, ClassOrStudent, SetStudentsAction } from './StudentsSlice';

type ClassOrGroupState = {
  classOrGroup: ClassOrStudent | null;
};

export const initialStateClassOrGroupState: ClassOrGroupState = {
  classOrGroup: null,
};

const setClassOrGroupReducer = (state: ClassOrGroupState, { payload }: PayloadAction<SetStudentsAction>) => {
  state.classOrGroup = payload.classOrGroup;
};

export const classOrGroupSlice = createSlice({
  name: 'benchmark-assessment-report-class-or-group',
  initialState: initialStateClassOrGroupState,
  reducers: {},
  extraReducers: {
    [studentsSlice.actions.setStudents.type]: setClassOrGroupReducer,
  },
});

import React from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

const createClient = (token: string, apiURL: string) => new ApolloClient({
  uri: apiURL,
  cache: new InMemoryCache({
    typePolicies: {
      Teacher: {
        keyFields: ['entityId'],
      },
      Student: {
        keyFields: ['childAccountId'],
      },
      Class: {
        keyFields: ['entityId'],
      },
    },
  }),
  headers: {
    authorization: `Bearer ${token}`,
  },
});

interface DataProviderProps {
  children: React.ReactNode;
  token: string;
  apiURL: string;
}

const DataProvider = ({ children, token, apiURL }: DataProviderProps) => (
  <ApolloProvider client={createClient(token, apiURL)}>
    {children}
  </ApolloProvider>
);

export default DataProvider;
